import React from 'react';
import './ReactLogo.scss';

const ReactLogo = () => {
  return (
    <section>
      <div className='logo'></div>
      <div className='logo'></div>
      <div className='logo'> </div>
      <div className='logo'></div>
    </section>
  );
};

export default ReactLogo;
